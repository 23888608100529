import React from "react";
import Layout from "../components/Layout";

const hr360 = () => (
  <Layout>
    <section className="hero is-primary is-bold is-medium">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section is-centered">
                <h1 className="title">HR360 Portal</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container section">
        <form
          action="https://www.hr360.com/autologin.aspx"
          method="POST"
          name="form1"
          id="form1"
        >
          <input
            type="hidden"
            name="ReturnURL"
            value="https://www.employeebenefitswebsite.com/hrgenius/index.php"
          />
          <div className="field">
            <label className="label">Username</label>
            <div className="control">
              <input
                name="Username"
                className="input"
                type="text"
                size="15"
                maxlength="60"
                id="Username"
                autocomplete="off"
                placeholder="Username"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                name="Password"
                className="input"
                id="Password"
                type="password"
                size="15"
                maxlength="15"
                placeholder="Password"
              />
            </div>
          </div>
          <input name="MM_form" type="hidden" id="MM_form" value="login" />
          <input
            className="button is-primary"
            name="submit"
            type="submit"
            id="submit"
            value="Login"
          />
        </form>
      </div>
    </section>
  </Layout>
);

export default hr360;
